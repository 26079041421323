import {AnalyticsBrowser} from "@segment/analytics-next"

export class Analytics {
  private instance: AnalyticsBrowser | null;

  constructor(instance: AnalyticsBrowser | null) {
    this.instance = instance;
  }

  identify(userId: string, traits?: any) {
    if (this.instance) {
      return this.instance.identify(userId, traits);
    }
    return Promise.resolve();
  }

  page(pageName: string, data?: any) {
    if (this.instance) {
      return this.instance.page(pageName, data);
    }
    return Promise.resolve();
  }

  track(event: string, properties?: any) {
    if (this.instance) {
      return this.instance.track(event, properties);
    }
    return Promise.resolve();
  }

  reset() {
    if (this.instance) {
      return this.instance.reset();
    }
    return Promise.resolve();
  }
}