import {createContext, ReactNode, useContext, useEffect, useMemo} from "react"
import {AnalyticsBrowser} from "@segment/analytics-next"
import {useIsClient} from "usehooks-ts"
import {Analytics} from "~/analytics/Analytics"

interface Profile {
  id: string;
  created_at: string;
  email: string;
  name: string | null;
  feature_flags?: { [key: string]: boolean } | null | undefined;
}

interface AnalyticsContextValue {
  analytics: Analytics;
}

const AnalyticsContext = createContext<AnalyticsContextValue | null>(null)

export const AnalyticsContextProvider = ({
  environment,
  profile,
  children,
}: {
  environment: "development" | "production";
  profile: Profile | null;
  children?: ReactNode;
}) => {
  const isClient = useIsClient()

  // Create or retrieve the AnalyticsBrowser instance only on the client.
  const analyticsBrowserInstance = useMemo(() => {
    if (isClient) {
      return AnalyticsBrowser.load({
        writeKey:
          environment === "development"
            ? "IEGvwPY7trG35oYfbBd209d89yVshfbk"
            : "np1a3HaNnxspckCzFU7pEabAgmVi7el4",
      })
    }
    return null
  }, [isClient, environment])

  const analytics = useMemo(() => new Analytics(analyticsBrowserInstance), [
    analyticsBrowserInstance,
  ])

  useEffect(() => {
    if (isClient && profile && analyticsBrowserInstance) {
      analyticsBrowserInstance.reset().then(() => {
        analyticsBrowserInstance.identify(profile.id, profile)
      })
    }
  }, [isClient, profile, analyticsBrowserInstance])

  return (
    <AnalyticsContext.Provider value={{analytics}}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)
  if (!context)
    throw new Error("You can only use AnalyticsContext within its provider")
  return context
}

export const useTrackIdentity = (userId: string) => {
  const {analytics} = useAnalyticsContext()
  return analytics.identify(userId)
}

export const useTrackPage = (pageName: string, data?: any) => {
  const {analytics} = useAnalyticsContext()
  useEffect(() => {
    analytics.page(pageName, data)
  }, [])
}

export const useTrackEvent = (name: string, data?: any) => {
  const {analytics} = useAnalyticsContext()
  return analytics.track(name, data)
}

export const useTrackLogout = () => {
  const {analytics} = useAnalyticsContext()
  return analytics.reset()
}